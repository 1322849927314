<template>
  <div>
    <common-header></common-header>

    <div class="warp">
      <ul class="items-ul"
      v-infinite-scroll="loadMore"
      infinite-scroll-disabled="loading"
      infinite-scroll-distance="100"
      infinite-scroll-immediate-check="false"
      >
        <li class="items" v-for="(item, index) in lists.list" :key="index" @click="$router.push(`/account/afterdetails?id=${item.id}`)">
          <div class="top">
            <div class="l">订单编号: {{item.orderCode}}</div>
            <div class="type">{{item.type == 1 ? '退货' : (item.type ==2 ? '换货' : '退订单')}}</div>
          </div>
          <div class="commodity-name"><b>{{item.type ==3 ? '全退' : item.commodityName}}</b></div>
          <div class="bottom">
            <div class="l">退货：￥{{item.amount}}</div>
            <div class="r">{{item.status == 1 ? '申请中' : (item.status == 2 ? '已完成' : (item.status == 3 ? '已驳回' : '已取消'))}}</div>
          </div>
        </li>
      </ul>
      <no-data v-if="nodata"></no-data>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
export default {
  data() {
    return {
      lists: '',
      nodata: false,
      loading: false,
      pageNum: 1,
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init(pageNum, add) {
      this.getTSaleServicePage({pageNum: pageNum || 1}).then((res)=> {
        if (add) {
          this.lists.list = this.lists.list.push(res.data.list);
          this.pageNum = res.data.pageNum;
        } else {
          this.lists = res.data;
          this.nodata = res.data.list.length > 0 ? false : true;
        }
      })
    },
    ...mapActions('account', [
      'getTSaleServicePage'
    ]),
    loadMore() {
      this.loading = true;
      if (this.pageNum < this.lists.pageNum) {
        this.init(this.pageNum + 1, 'add')
      } else {
        // this.$toast('没有更多了');
      }
    },
  },
}
</script>
<style lang='less' scoped>
  .items-ul{
    padding-top: .2rem;
  }
  .items{
    background: #fff;
    padding: .2rem;
    font-size: .28rem;
    margin-bottom: .2rem;
    color: #333;
    border-radius: .1rem;
    .bottom{
      .r{
        color: #ff7241;
      }
    }
    .type{
      color: #9e9e9e;
    }
    .commodity-name{
      padding: .2rem 0;
    }
    .top,.bottom{
      display: flex;
      justify-content: space-between;
    }
  }
</style>